import React from 'react';

import {Contact} from "../../components";
import Layout from "../Layout";

const ContactPage = () => (
    <Layout isContact={true}>
        <Contact />
    </Layout>
);

export default ContactPage;

import React, { Component } from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";

import Card from "../Card";
import { PROJECTS_LIST } from "../../containers/Projects/constants";

class ProjectList extends Component {
  componentDidMount() {
    const item = document.querySelector(
      ".restore-" + this.props.location.state
    );
    if (item) {
      item.scrollIntoView();
    }
  }
  
  render() {
    return (
      <Container>
        {Object.values(PROJECTS_LIST).map(project => {
          return <Card key={project.title} project={project} />;
        })}
      </Container>
    );
  }
}

export default withRouter(ProjectList);

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: row wrap;
  margin-top: 10px;

  @media (max-width: 415px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

import React from "react";
import { Link, withRouter } from "react-router-dom";
import styled from "styled-components";

import TA_logo_ARCHI from "../../assets/logos/TA_logo_ARCHI_2.svg";
import TA_logo_TOTEM from "../../assets/logos/TA_logo_TOTEM_2.svg";
import { SCREENS_LIST } from "./constants";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      currentLink: props.location.pathname, 
      isMobile: window.innerWidth < 500,
      isMobileViewOpen: false
     };
  }

  componentDidMount() {
    window.addEventListener('orientationchange', this.updateHeader)
  }

  componentWillUnmount() {
    window.removeEventListener('orientationchange', this.updateHeader)
  }

  updateHeader = () => {
    window.setTimeout(() => {
      const isMob = window.innerWidth < 500;
      this.setState({ isMobile: isMob });
    }, 200);
  }

  toggleMobileView = () => this.setState({ isMobileViewOpen: !this.state.isMobileViewOpen });

  render() {
    return (
      <Container>
        <LinkLogoWrapper to={SCREENS_LIST.projects}>
          <Logo src={TA_logo_TOTEM} />
        </LinkLogoWrapper>

        {!this.state.isMobile 
          ? 
          <ContainerLinks>
            <ContainerMainLinks>
              <LinkWrapper to={SCREENS_LIST.news}>
              <Bullet displayIt={this.state.currentLink === SCREENS_LIST.news}>
                ●
              </Bullet>
              News
            </LinkWrapper>

            <LinkWrapper to={SCREENS_LIST.info}>
              <Bullet displayIt={this.state.currentLink === SCREENS_LIST.info}>
                ●
              </Bullet>
              Info
            </LinkWrapper>

            <LinkWrapper to={SCREENS_LIST.contact}>
              <Bullet
                displayIt={this.state.currentLink === SCREENS_LIST.contact}
              >
                ●
              </Bullet>
              Contact
            </LinkWrapper>
          </ContainerMainLinks>
        </ContainerLinks> 
          : 
          <ContainerMobileHeader onClick={this.toggleMobileView}>
            <BulletMobile>●</BulletMobile>
            {
              this.state.isMobileViewOpen 
              && <MobileNav>
                    <LinkWrapper to={SCREENS_LIST.news}>News</LinkWrapper>
                    <LinkWrapper to={SCREENS_LIST.info}>Info</LinkWrapper>
                    <LinkWrapper to={SCREENS_LIST.contact}>Contact</LinkWrapper>
                </MobileNav>
            }
          </ContainerMobileHeader>
        
        }

        <LinkLogoWrapper to={SCREENS_LIST.projects} isleft="true">
          <Logo src={TA_logo_ARCHI} />
        </LinkLogoWrapper>
      </Container>
    );
  }
}

export default withRouter(Header);

const Container = styled.div`
  width: 100vw;

  height: 10vh;
  min-height: 50px;

  @media (max-width: 700px) {
    height: 5vh;
  }

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  background-color: white;
  z-index: 1000;
`;

const LinkWrapper = styled(Link)`
  color: inherit;
  text-decoration: none;
  font-family: "SuisseIntl Medium";
  font-size: calc(6px + 3.3vw);
  padding: 0 20px;

  @media (max-width: 450px) {
    padding: 0 10px;
  }
  display: flex;
  align-items: center;
`;

const ContainerLinks = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-left: 20px;

  @media (max-width: 700px) {
    margin-left: 0px;
  }
`;

const ContainerMobileHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-left: 2vw;
`;

const ContainerMainLinks = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const LinkLogoWrapper = styled(Link)`
  color: inherit;
  text-decoration: none;
  margin: 20px;

  @media (max-width: 450px) {
    margin: 20px 10px;
  }

  display: flex;
  justify-content: center;
  align-items: center;
  
  ${props => (props.isleft ? "right: 0%;" : "left: 0%;")};
`;

const Logo = styled.img`
  height: calc(3px + 2.8vw);

  @media (max-width: 500px) {
    height: 20px;
  }
`;

const Bullet = styled.span`
  font-family: "SuisseIntl Bold";
  font-size: calc(12px + 3vw);
  margin: 0 0 0 calc(-1.5vw - 6px);

  @media (max-width: 700px) {
    margin: 0 0 0 calc(-1.5vw - 10px);
  }

  @media (max-width: 415px) {
    margin: 0 calc(-1.5vw - 10px);
    padding-bottom: 3px;
  }

  position: absolute;
  ${props => !props.displayIt && "display: none;"};
`;

const BulletMobile = styled.span`
  font-family: "SuisseIntl Bold";
  font-size: calc(12px + 3.5vw);
  cursor: pointer;

  padding-bottom: 3px;
`;

const MobileNav = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;

  margin-left: 15px;
  margin-top: 24px;
  background-color: white;

  z-index: 1000000;
`;

import React, { Component } from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";

class Card extends Component {
  onClickCard = () => this.props.history.push(`/projects/${this.props.project.path}`);

  render() {
    const { title, additionalInfo, picture_url_description, picture_HD } = this.props.project;

    return (
      <Container className={`restore-${this.props.project.path}`} onClick={this.onClickCard}>
        {/* <Informations>
          <div>
            <Bullet>●</Bullet>
            <Title>{title}</Title>
          </div>
          <ContainerDescription>
            <Description>{additionalInfo.type}</Description>
            <Description>{additionalInfo.date}</Description>
            <Description>{additionalInfo.location}</Description>
            {additionalInfo.d1 && <Description>{additionalInfo.d1}</Description>}
            {additionalInfo.d2 && <Description>{additionalInfo.d2}</Description>}
            {additionalInfo.d3 && <Description>{additionalInfo.d3}</Description>}
            {additionalInfo.d4 && <Description>{additionalInfo.d4}</Description>}
          </ContainerDescription>
        </Informations> */}
        <Image img_low={picture_url_description} img_high={picture_HD} />
      </Container>
    );
  }
}

export default withRouter(Card);

const Container = styled.div`
  width: 23.5vw;
  height: 23.5vw;
  cursor: pointer;
  margin: 0 0 2vw 0;

  :not(:nth-child(4n)) {
    margin: 0 2vw 2vw 0;
  }

  @media (max-width: 701px) {
    width: 48vw;
    height: 48vw;
    margin: 0 0 3.5vw 0;

    :not(:nth-child(n)) {
      margin: 0 3.5vw 3.5vw 0;
    }
  }

  @media (max-width: 415px) {
    width: 100vw;
    height: 100vw;
    margin: 0 0 23px 0;
  }
`;

const Informations = styled.div`
  height: inherit;
  width: inherit;
  display: flex;
  flex-direction: column;
  position: absolute;
  opacity: 0;
  z-index: 0;

  @media (min-width: 500px) and (min-height: 500px) {
    &:hover {
      opacity: 1;
      z-index: 10000;
      background-color: white;
    }
  }
`;

const Image = styled.div`
  width: 100%;
  height: 100%;
  z-index: 100000;

  background-image: url(${(props) => props.img_high}), url(${(props) => props.img_low});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;

  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);

  &:hover {
    filter: none;
    -webkit-filter: grayscale(0);
  }
`;

const Title = styled.span`
  font-family: "SuisseIntl Medium";
  font-size: 36px;
`;
const Description = styled.span`
  text-align: left;
  font-family: "SuisseIntl Medium";
  font-size: 16px;
`;

const Bullet = styled.span`
  font-family: "SuisseIntl Bold";
  font-size: 46px;

  @media (max-width: 700px) {
    font-size: 18px;
  }

  margin-right: 5px;
`;

const ContainerDescription = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 10px 10px 33px;
`;

export const VAUD = `
Aclens
Agiez
Aigle
Allaman
Apples
Arnex-sur-Nyon
Arnex-sur-Orbe 
Arzier-Le Muids
Assens
Aubonne
Avenches

Ballaigues
Ballens
Bassins
Baulmes
Bavois
Begnins
Belmont-sur-Lausanne
Belmont-sur-Yverdon
Bercher
Bercher
Berolle
Bettens
Bex
Bière
Bioley-Magnoux
Bioley-Orjulaz
Blonay
Bofflens
Bogis-Bossey
Bonvillars
Borex
Bottens
Bougy-Villars
Boulens
Bourg-en-Lavaux
Bournens
Boussens
Bremblens
Bretigny-sur-Morrens
Bretonnières
Buchillon
Bullet
Bursinel
Bursins
Burtigny
Bussigny
Bussy-Chardonney
Bussy-sur-Moudon



Chamblon
Champagne
Champtauroz
Champvent
Chardonne
Château-d'Oex
Chavannes-de-Bogis
Chavannes-des-Bois
Chavannes-le-Chêne
Chavannes-le-Veyron
Chavannes-près-Renens
Chavannes-sur-Moudon
Chavornay
Chêne-Pâquier
Cheseaux-Noréaz
Cheseaux-sur-Lausanne
Chéserex
Chessel
Chevilly
Chevroux
Chexbres
Chigny
Clarmont
Coinsins
Commugny
Concise
Coppet
Corbeyrier
Corcelles-le-Jorat
Corcelles-près-Concise
Corcelles-près-Payerne
Corseaux
Corsier-sur-Vevey
Cossonay
Cottens
Crans-près-Céligny
Crassier
Crissier
Cronay
Croy
Cuarnens
Cuarny
Cudrefin
Cugy
Curtilles



Daillens
Démoret
Denens
Denges
Dizy
Dompierre
Donneloye
Duillier
Dully



Echallens
Echandens
Echichens
Eclépens
Ecublens
Epalinges
Ependes
Essertes
Essertines-sur-Rolle
Essertines-sur-Yverdon
Etagnières
Etoy
Eysins



Faoug
Féchy
Ferreyres
Fey
Fiez
Fontaines-sur-Grandson
Forel (Lavaux)
Founex
Froideville



Genolier
Giez
Gilly
Gimel
Gingins
Givrins
Gland
Gollion
Goumoëns
Grancy
Grandcour
Grandevent
Grandson
Grens
Gryon



Henniez
Hermenches



Jongny
Jorat-Menthue
Jorat-Mézières
Jouxtens-Mézery
Juriens



La Chaux (Cossonay)
La Praz
La Rippe
La Sarraz
La Tour-de-Peilz
L'Abbaye
L'Abergement
Lausanne
Lausanne
Lavey-Morcles
Lavigny
Le Chenit
Le Lieu
Le Mont-sur-Lausanne
Le Vaud
Les Clées
Leysin
Lignerolle
L'Isle
Lonay
Longirod
Lovatens
Lucens
Luins
Lully
Lussery-Villars
Lussy-sur-Morges
Lutry



Maracon
Marchissy
Mathod
Mauborget
Mauraz
Mex
Mies
Missy
Moiry
Mollens
Molondin
Montagny-près-Yverdon
Montanaire
Montcherand
Montherod
Montilliez
Mont-la-Ville
Montpreveyres
Montreux
Montricher
Mont-sur-Rolle
Morges
Morrens
Moudon
Mutrux



Novalles
Noville
Nyon



Ogens
Ollon
Onnens
Oppens
Orbe
Orges
Ormont-Dessous
Ormont-Dessus
Orny
Oron
Orzens
Oulens-sous-Echallens



Pailly
Pampigny
Paudex
Payerne
Penthalaz
Penthaz
Penthéréaz
Perroy
Poliez-Pittet
Pompaples
Pomy
Prangins
Premier
Préverenges
Prévonloup
Prilly
Provence
Puidoux
Pully



Rances
Renens
Rennaz
Reverolle
Rivaz
Roche
Rolle
Romainmôtier-Envy
Romanel-sur-Lausanne
Romanel-sur-Morges
Ropraz
Rossenges
Rossinière
Rougemont
Rovray
Rueyres



Saint-Barthélemy
Saint-Cergue
Sainte-Croix
Saint-George
Saint-Légier - La Chiésaz
Saint-Livres
Saint-Oyens
Saint-Prex
Saint-Saphorin (Lavaux)
Saint-Sulpice
Saubraz
Savigny
Senarclens
Sergey
Servion
Sévery
Signy-Avenex
Suchy
Sullens
Suscévaz
Syens



Tannay
Tartegnin
Tévenon
Tolochenaz
Trélex
Trey
Treycovagnes
Treytorrens (Payerne)



Ursins



Valbroye
Valeyres-sous-Montagny
Valeyres-sous-Rances
Valeyres-sous-Ursins
Vallorbe
Vaulion
Vaux-sur-Morges
Vevey
Veytaux
Vich
Villars-Epeney
Villars-le-Comte
Villars-le-Terroir
Villars-Sainte-Croix
Villars-sous-Yens
Villarzel
Villeneuve
Vinzel
Vuarrens
Vucherens
Vufflens-la-Ville
Vufflens-le-Château
Vugelles-La Mothe
Vuiteboeuf
Vulliens
Vullierens
Vully-les-Lacs



Yens
Yverdon-les-Bains
Yvonand
Yvorne
`;
import React from "react";
import styled from "styled-components";

import Layout from "../Layout";
import { INFO, INTRO } from "./constants";

class Info extends React.Component {
  state = {
    isMobile: window.innerWidth < 500,
  };

  componentDidMount() {
    window.addEventListener("orientationchange", this.updateHeader);
  }

  componentWillUnmount() {
    window.removeEventListener("orientationchange", this.updateHeader);
  }

  updateHeader = () => {
    window.setTimeout(() => {
      const isMob = window.innerWidth < 500;
      this.setState({ isMobile: isMob });
    }, 200);
  };

  render() {
    return (
      <Layout>
        <Container>
          <ContainerInfo>
            <Introduction>{INTRO.a}</Introduction>
          </ContainerInfo>

          <ImageContainer
            src="https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Info%2FSkidegate_village%2C_British_Columbia_(253).jpg?alt=media&token=47aa9a41-b927-4fcb-a743-8f204b381bd7"
            alt="Photo de l'équipe"
          />

          {/* <ContainerInfo isCV>
            {INFO.map((p, index) => {
              return (
                <Person key={index}>
                  <RowFlex>
                    <SpanContainer>
                      <Bullet>●</Bullet>
                      <Title>
                        {INFO[index].name}{" "}
                        {this.state.isMobile ? INFO[index].diploma.slice(0, 10) : INFO[index].diploma}
                      </Title>
                    </SpanContainer>
                  </RowFlex>

                  {INFO[index].experience.map((exp, i) => {
                    return (
                      <RowFlex key={i}>
                        <Text>{exp.date}</Text>
                        <Text isSecond>
                          {exp.description} {!!exp.more && exp.more}
                        </Text>
                      </RowFlex>
                    );
                  })}
                </Person>
              );
            })}
          </ContainerInfo> */}
        </Container>
      </Layout>
    );
  }
}

export default Info;

const ImageContainer = styled.img`
  width: 50%;
  max-width: 1200px;

  margin-left: 13%;

  @media (max-width: 500px) {
    margin-left: 0%;
  }

  @media (max-width: 500px) {
    width: 100%;
  }
`;

const SpanContainer = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;

  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-left: 0;
  @media (max-width: 500px) {
    align-items: center;
  }
`;

const Person = styled.div`
  margin-bottom: 20px;
`;

const RowFlex = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: baseline;
`;

const Text = styled.span`
  font-family: "SuisseIntl Medium";
  font-size: 16px;
  width: ${(props) => (props.isSecond ? "70%" : "30%")};

  @media (max-width: 415px) {
    width: ${(props) => (props.isSecond ? "70%" : "35%")};
  }

  margin-bottom: 10px @media (max-width: 700px) {
    font-size: 12px;
  }

  @media (max-width: 650px) {
    font-size: 10px;
  }

  @media (max-width: 500px) {
    font-size: 18px;
  }

  @media (max-width: 363px) {
    font-size: 16px;
  }
`;

const Title = styled(Text)`
  @media (min-width: 393px) {
    margin-top: 3px;
  }

  width: 100% !important;
`;

const Introduction = styled.span`
  font-family: "SuisseIntl Medium";
  width: 100%;

  font-size: 18px;
  margin-bottom: 10px;

  @media (max-width: 363px) {
    font-size: 16px;
  }
`;

const ContainerInfo = styled.div`
  width: 70%;
  margin-bottom: 10px;

  ${(props) =>
    props.isCV &&
    `
      margin-top: 10px;
    `} @media (max-width: 1080px) {
    width: 70%;
  }

  @media (max-width: 940px) {
    width: 80%;
  }

  @media (max-width: 750px) {
    width: 90%;
  }

  @media (max-width: 500px) {
    width: 100%;
  }

  display: flex;
  flex-direction: column;

  @media (max-width: 500px) {
    text-align: left;
  }
`;

const Bullet = styled.span`
  font-family: "SuisseIntl Bold";
  font-size: 26px;

  @media (max-width: 700px) {
    font-size: 18px;
  }

  @media (max-width: 500px) {
    font-size: 16px;
  }

  @media (max-width: 450px) {
    font-size: calc(12px + 3.5vw);
  }
`;

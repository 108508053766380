import React from "react";
import { Route, Switch } from "react-router-dom";
import styled from "styled-components";

import { ContactPage, Info, Landing, News, Projects } from "./containers";
import { Project } from "./components";

const App = () => (
  <AppContainer>
    <Switch>
      <Route path="/" exact component={Landing} />
      <Route exact path="/projects/:id" component={Project} />
      <Route path="/projects" component={Projects} />
      <Route path="/news" component={News} />
      <Route path="/info" component={Info} />
      <Route path="/contact" component={ContactPage} />
      <Route component={Landing} />
    </Switch>
  </AppContainer>
);

export default App;

const AppContainer = styled.div`
  width: 100vw;
  height: 100%;
`;

import PDL_desc_picture from "../../assets/pictures/projects_list/1_A_PDL_North_003_ACL.jpg";
import BRU_desc_picture from "../../assets/pictures/projects_list/001_BRU.png";
import DES_desc_picture from "../../assets/pictures/projects_list/1_WEB_DES_Vue-Nord.png";
import MHN_desc_picture from "../../assets/pictures/projects_list/1_MHN_outside.jpg";
import BLVD_desc_picture from "../../assets/pictures/projects_list/1_WEB_BELV_Vue-Ext.jpg";
import SIVI_desc_picture from "../../assets/pictures/projects_list/1_WEB_SIVI_Vue-ext.jpg";
import BAINS_desc_picture from "../../assets/pictures/projects_list/1_BAINS_Archi_genf_rénov_1.jpg";
import SIER_desc_picture from "../../assets/pictures/projects_list/1_WEB_Sierre_Image_TEST.jpg";
import CLAP_desc_picture from "../../assets/pictures/projects_list/1_IMG7271.jpg";
import RIANT_desc_picture from "../../assets/pictures/projects_list/01_View_01_001.jpg";
import NEI_desc_picture from "../../assets/pictures/projects_list/01_NEI_190905_IMG.jpg";
import PAGODE_desc_picture from "../../assets/pictures/projects_list/Pagode_MAIN.jpg";
import Bellevue_desc_picture from "../../assets/pictures/projects_list/BELLEVUE_MAIN.jpg";
import OlivSquash_desc_picture from "../../assets/pictures/projects_list/OlivSquash.jpg";
import Usine_desc_picture from "../../assets/pictures/projects_list/00_Usine2_AC.jpg";

export const PROJECTS_LIST = {
  "069_USINE": {
    title: "069 USINE",
    path: "069_USINE",
    additionalInfo: {
      type: "Concours ouvert",
      location: "Septembre 2020",
      d1: "St Sulpice (VD)",
      d2: "Nouvelle usine de production d’eau potable",
      d3: "Avec Schnetzer Puskas ingénieurs SA et Eranthis Architecte-paysagiste",
    },
    picture_url_description: Usine_desc_picture,
    picture_HD:
      "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F069_USINE%2F00_Usine2_AC.jpg?alt=media&token=b09a1408-e8ec-4126-b0bb-0556836bcee7",
    main_description: {
      STATUT: "Concours",
      SITE: "Commune de St Sulpice, Suisse",
      DATE: "Septembre 2020",
      PROGRAMME: "Construction de la nouvelle usine de production d’eau potable",
      COLLABORATEURS: "Avec Schnetzer Puskas ingénieurs SA et Eranthis Architecte-paysagiste",
    },
    pictures: [
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F069_USINE%2F01_Usine1_AC.jpg?alt=media&token=365d0e3c-c242-47da-98cc-bf00c3f72843",
        isFullBleed: true,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F069_USINE%2F02_USINE_200828_plan_masse_EB_v2020_v2019.jpg?alt=media&token=ba734f10-c429-4704-9e57-9d4353909d46",
        isFullBleed: true,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F069_USINE%2F03_Usine2_AC.jpg?alt=media&token=50d89f35-3d31-48bf-92fb-f081e44e8299",
        isFullBleed: true,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F069_USINE%2F04_USINE_REZ_INF_PLAN_500-.jpg?alt=media&token=1b80c89d-9bab-4ba5-aeb9-94773b01fa40",
        isFullBleed: false,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F069_USINE%2F05_USINE_REZ_SUP_PLAN_500-.jpg?alt=media&token=056cfec1-a814-4711-bbf6-5a7196fea472",
        isFullBleed: false,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F069_USINE%2F06_USINE_SS1_SUP_PLAN_200-.jpg?alt=media&token=055d9510-25b8-445e-97c1-16adabf1ac7b",
        isFullBleed: false,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F069_USINE%2F07_USINE_COUPES_201-.jpg?alt=media&token=2711dffd-98a9-498e-adc8-a9ac63136825",
        isFullBleed: true,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F069_USINE%2F08_USINE_COUPES_200-.jpg?alt=media&token=2e85eff5-a313-4e09-9ed7-1f18aeb1b83d",
        isFullBleed: true,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F069_USINE%2F09_USINE_COUPES_202-.jpg?alt=media&token=16d2ce25-e087-4fa9-a369-cfafcc796a56",
        isFullBleed: true,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F069_USINE%2F10_Usine3_AC.jpg?alt=media&token=19307f3e-1c77-40c1-9efc-d150518873c5",
        isFullBleed: true,
      },
    ],
  },
  "028_O+S": {
    title: "028 O+S",
    path: "028_O+S",
    additionalInfo: {
      type: "Réalisé",
      location: "Octobre 2019",
      d1: "Londres (UK)",
      d2: "Un restaurant pour Olive and Squash",
      d3: "Avec Architecture Social Club",
    },
    picture_url_description: OlivSquash_desc_picture,
    picture_HD:
      "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F028_O%2BS%2F01-DSC_2478-Edit-HIRES.jpg?alt=media&token=4ac1aaf3-1ca3-4360-a53f-92b811e2ec8c",
    main_description: {
      STATUT: "Réalisé",
      SITE: "Londres, UK",
      DATE: "Octobre 2019",
      PROGRAMME: "Un restaurant pour Olive and Squash : vente de plats frais et locaux",
      COLLABORATEURS: "Avec Architecture Social Club",
    },
    pictures: [
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F028_O%2BS%2F01-DSC_2478-Edit-HIRES.jpg?alt=media&token=4ac1aaf3-1ca3-4360-a53f-92b811e2ec8c",
        isFullBleed: true,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F028_O%2BS%2F02_WEB_O%2BS_PLAN.jpg?alt=media&token=ce8504bc-ab6b-4d61-91e4-9d4d25bd0628",
        isFullBleed: true,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F028_O%2BS%2F03_WEB_O%2BS_SECTION-2.jpg?alt=media&token=9e5ba406-c2b7-4de1-aa32-3b78f9cde024",
        isFullBleed: true,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F028_O%2BS%2F04_WEB_O%2BS_SECTION.jpg?alt=media&token=41b9c844-d8ba-4f5a-b7bf-1ed1e04a9199",
        isFullBleed: true,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F028_O%2BS%2F05_WEB_O?alt=media&token=00a312fb-3793-4564-9fcc-1fddba1998c3",
        isFullBleed: false,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F028_O%2BS%2F06_20200227-DSC_2375-Edit-Edit-HIRES%20copy.jpg?alt=media&token=a30a09cb-fa3b-4739-8dc5-4ab7ee2865f2",
        isFullBleed: true,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F028_O%2BS%2F07_-DSC_2367-Edit-HIRES.jpg?alt=media&token=5c885ef7-d582-403a-8317-934654acbf2e",
        isFullBleed: false,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F028_O%2BS%2F08_20200227-DSC_2401-HIRES%20copy.jpg?alt=media&token=0d00fb19-6eb1-4398-a026-63f7fb70f696",
        isFullBleed: true,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F028_O%2BS%2F10_20200227-DSC_2414-Edit-HIRE.jpg?alt=media&token=83ca354f-6b25-49b8-83de-8a289b220f79",
        isFullBleed: true,
      },
    ],
  },
  "063_BELLEVUE": {
    title: "063 BELLEVUE",
    path: "063_BELLEVUE",
    additionalInfo: {
      type: "Concours ouvert",
      date: "3 ème Prix",
      location: "Janvier 2020",
      d1: "Bellevue (GE)",
      d2: "Bâtiment communal et Auberge",
      d3: "Avec Ingphi SA Ingénieurs civils et Eranthis Architecte-paysagiste",
    },
    picture_url_description: Bellevue_desc_picture,
    picture_HD:
      "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F063_BELLEVUE_1%2F00_BEL_200115_Lake_View.jpg?alt=media&token=fdb76819-49c7-44e2-85ea-bd3f7c31027f",
    main_description: {
      STATUT: "Concours, 3 ème Prix",
      SITE: "Commune de Bellevue, Suisse",
      DATE: "Janvier 2020",
      PROGRAMME: "Bâtiment communal et Auberge-Restaurant à Port Gitana",
      COLLABORATEURS: "Avec Ingphi SA Ingénieurs civils et Eranthis Architecte-paysagiste",
    },
    pictures: [
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F063_BELLEVUE_1%2F01_BEL_200115_Lake_View.jpg?alt=media&token=36d0ca55-f75d-4bce-bdbf-7789d8f98766",
        isFullBleed: true,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F063_BELLEVUE_1%2F02_063_BEL_Ref.jpg?alt=media&token=0956e860-5af2-471f-93dd-7796760f98b9",
        isFullBleed: false,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F063_BELLEVUE_1%2F03_PLAN%20MASSE.jpg?alt=media&token=6cb5fea6-1915-445c-a128-4f87efb6eabd",
        isFullBleed: true,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F063_BELLEVUE_1%2F04_REZ-.jpg?alt=media&token=d98281ff-2674-49ac-92c5-270a3d98b8d6",
        isFullBleed: true,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F063_BELLEVUE_1%2F05_ETAGES-2%20(1).jpg?alt=media&token=f84c4c0e-8729-4d83-a38f-4db9785f7baa",
        isFullBleed: true,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F063_BELLEVUE_1%2F06_ELEVATION-.jpg?alt=media&token=4afd466a-d72a-4743-947e-9049458edcba",
        isFullBleed: true,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F063_BELLEVUE_1%2F07_COUPE%20LON-.jpg?alt=media&token=3dbd3c8f-6e42-43ad-9cdc-8cca43f9ae18",
        isFullBleed: true,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F063_BELLEVUE_1%2F08_COUPE%20COU-.jpg?alt=media&token=62e16b5d-3c6f-42b7-9754-e23d6248470e",
        isFullBleed: true,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F063_BELLEVUE_1%2F09_DETAIL.jpg?alt=media&token=d9076895-7326-4334-8e7b-8b46cf04b0cc",
        isFullBleed: false,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F063_BELLEVUE_1%2F10_E02D-03-2.jpg?alt=media&token=8a67e7ba-b077-4372-b994-75e4d9e477c6",
        isFullBleed: true,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F063_BELLEVUE_1%2F11_I01B-03.jpg?alt=media&token=3e0cafc6-9b11-47ff-af9a-9c9ae933eacb",
        isFullBleed: true,
      },
    ],
  },
  "054_PAGODE": {
    title: "054 PAGODE",
    path: "054_PAGODE",
    additionalInfo: {
      type: "Octobre 2019",
      date: "Indre (FR)",
      location: "Maison pour une famille",
    },
    picture_url_description: PAGODE_desc_picture,
    picture_HD: PAGODE_desc_picture,
    main_description: {
      STATUT: " Permis de construire",
      SITE: "St Michel-en-Brenne (FR)",
      DATE: "Octobre 2019",
      PROGRAMME: "Maison pour une famille",
    },
    pictures: [
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F054_PAGODE_2%2F01_WEB_BRAULT_IMAGE_02-1.jpg?alt=media&token=5594b68f-b452-4918-bff9-a98f9349c298",
        isFullBleed: true,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F054_PAGODE_2%2F02_BRAU_191226_planmasse.jpg?alt=media&token=a7497e5b-966e-4ca7-87e5-2f00d0536007",
        isFullBleed: false,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F054_PAGODE_2%2F02_WEB_BRAULT_PLAN.jpg?alt=media&token=a4966ff7-01a1-48f6-bf59-1f86a731f7a5",
        isFullBleed: false,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F054_PAGODE_2%2F03_WEB_BRAULT_PLAN-2.jpg?alt=media&token=a2d4e22e-37a6-4e8c-83e4-c6c497d404f2",
        isFullBleed: false,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F054_PAGODE_2%2F04_WEB_BRAULT_COUPE.jpg?alt=media&token=21b0fcb9-b390-4015-a573-35a384ac458f",
        isFullBleed: true,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F054_PAGODE_2%2F05_WEB_BRAULT_ELEV.jpg?alt=media&token=85740550-d7f8-44f5-b3a0-442e3f12c93f",
        isFullBleed: true,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F054_PAGODE_2%2F06_WEB_BRAULT_IMAGE_03.jpg?alt=media&token=3902c788-9b16-453e-9ec1-a756480c9806",
        isFullBleed: true,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F054_PAGODE_2%2F07_WEB_BRAULT_IMAGE_03.jpg?alt=media&token=d9f6f759-4050-4970-95ed-cec911ff33ab",
        isFullBleed: true,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F054_PAGODE_2%2F08_WEB_BRAULT_IMAGE_01.jpg?alt=media&token=38fdf218-2a79-4f49-bfa0-40f3aa93789f",
        isFullBleed: true,
      },
    ],
  },
  "060_NEIRIVUE": {
    title: "060 NEIRIVUE",
    path: "060_NEIRIVUE",
    additionalInfo: {
      type: "Concours ouvert",
      date: "Tour final",
      location: "Septembre 2019",
      d1: "Neirivue (FR)",
      d2: "Ecole à Neirivue",
      d3: "Avec Eranthis Architecte-paysagiste",
    },
    picture_url_description: NEI_desc_picture,
    picture_HD:
      "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F060_NEIRIVUE%2F01_NEI_190905_IMG.jpg?alt=media&token=ea5330eb-587a-4fb3-b355-d7e8f117d62e",
    main_description: {
      STATUT: "Concours, Tour final",
      SITE: "Commune de Neirivue",
      DATE: "Septembre 2019",
      PROGRAMME: "Nouvelle école primaire et enfantine, place d’activité villageoise",
      COLLABORATEURS: "Eranthis Architecte-paysagiste",
    },
    pictures: [
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F060_NEIRIVUE%2F01_NEI_190905_IMG.jpg?alt=media&token=ea5330eb-587a-4fb3-b355-d7e8f117d62e",
        isFullBleed: true,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F060_NEIRIVUE%2F02_NEIRIVUE_190903_Plans_GA_PAP-1.jpg?alt=media&token=f4be31b9-4be8-4e22-ad4a-eb72321a0de3",
        isFullBleed: true,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F060_NEIRIVUE%2F03_ETH-BIB-Neirivue-LBS_H1-025266%20copy.jpg?alt=media&token=885bafd1-65b9-466d-8196-cf533e9cf84d",
        isFullBleed: false,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F060_NEIRIVUE%2F04_PLANS-.jpg?alt=media&token=e76f9f8a-28a4-4b9d-875c-3b0f5a2e9d62",
        isFullBleed: false,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F060_NEIRIVUE%2F04_REZ-.jpg?alt=media&token=6ed1ccc2-44c2-410b-83cf-37ddb7a91be2",
        isFullBleed: true,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F060_NEIRIVUE%2F05_NEI_COUPE1-.jpg?alt=media&token=e9050f33-cb95-408c-a58a-e214827a2568",
        isFullBleed: true,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F060_NEIRIVUE%2F06_NEI_COUPE2-.jpg?alt=media&token=02c96d62-89f5-411b-be3e-7a410a55c136",
        isFullBleed: true,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F060_NEIRIVUE%2F07_NEI_FACADE-.jpg?alt=media&token=44956d79-f98a-4fad-9a06-f090a40e411e",
        isFullBleed: true,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F060_NEIRIVUE%2F08_NEIRIVUE_190905_Elev.jpg?alt=media&token=3d0da9dc-14ec-4ce8-ac04-babf391d8b41",
        isFullBleed: true,
      },
    ],
  },
  "055_RIANT": {
    title: "055 RIANT",
    path: "055_RIANT",
    additionalInfo: {
      type: "Mandats d’étude parallèles",
      date: "Mai 2019",
      location: "Lausanne (VD)",
      d1: "Nouveaux logements et transformation d’une ferme protégée",
      d2: "Avec Hofmann + Gailloud Architectes",
      d3: "et Cécile Albana Presset architecte-paysagiste",
    },
    picture_url_description: RIANT_desc_picture,
    picture_HD:
      "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F055_RIANT%2F01_View_01_001.jpg?alt=media&token=0ae01b4b-0830-49e7-8731-2b1b2732ae27",
    main_description: {
      STATUT: "Mandats d’étude parallèles",
      SITE: "Domaine de Riant-pré",
      DATE: "Mai 2019",
      PROGRAMME:
        "70 logements et transformation d’une ferme protégée en crèche et bureaux pour Realitim managment SA",
      COLLABORATEURS: "Hofmann + Gailloud Architectes, Cécile Albana Presset architecte-paysagiste",
    },
    pictures: [
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F055_RIANT%2F01_View_01_001.jpg?alt=media&token=f376daaf-af00-4cc9-b5d0-18e55a7c6e59",
        isFullBleed: true,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F055_RIANT%2F02_Plan%20masse_1_500-Sheet%20Title.jpg?alt=media&token=59817bff-b70d-4c4c-9095-fbef23e152ad",
        isFullBleed: true,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F055_RIANT%2F03_A0_200_REZ-Description-1.jpg?alt=media&token=3e785128-8d5c-47fe-90a6-e3235044fd5c",
        isFullBleed: true,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F055_RIANT%2F04_A0_200_P_TYPE-Description-2.jpg?alt=media&token=efca036a-a952-4fae-966e-66a38248dbaa",
        isFullBleed: true,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F055_RIANT%2F05_Coupes_courte_BB_1_200-Sheet%20Title.jpg?alt=media&token=3412cbc9-3a9f-46d8-9cf4-5ed6a440909a",
        isFullBleed: true,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F055_RIANT%2F06_Coupes_courte_CC_1_200-Sheet%20Title.jpg?alt=media&token=e7462978-2bbf-486e-94d0-1df3ef3d36f3",
        isFullBleed: true,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F055_RIANT%2F07_Coupes_longue_BB_1_200-Sheet%20Title.jpg?alt=media&token=f2f376cc-8233-4f5c-9139-9ea5509a91bd",
        isFullBleed: true,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F055_RIANT%2F08_View_02_002.jpg?alt=media&token=90394318-108d-4628-b966-86c4a3ff1a4a",
        isFullBleed: true,
      },
    ],
  },
  "049_CLAP3": {
    title: "049 CLAP3",
    path: "049_CLAP3",
    additionalInfo: {
      type: "Construit",
      date: "Avril 2019",
      location: "Genève (GE)",
      d1: "Rénovation d’un appartement",
      d2: "Avec Livit Sa",
      d3: "Photos@Thomas Brasey",
    },
    picture_url_description: CLAP_desc_picture,
    picture_HD:
      "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F049_CLAP3%2F1_IMG7271.jpg?alt=media&token=7e7f4cd5-1aef-4b25-a8bb-9d5f2bf52682",
    main_description: {
      STATUT: "Construit",
      SITE: "Place Edouard Claparède 3, Genève (GE)",
      DATE: "Avril 2019",
      PROGRAMME: "Rénovation complète d’un appartement",
      CLIENT: "Livit Sa",
      PHOTOGRAPHIES: "Thomas Brasey",
      COLLABORATEURS: "Livit Sa",
    },
    pictures: [
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F049_CLAP3%2F1_IMG7271.jpg?alt=media&token=7e7f4cd5-1aef-4b25-a8bb-9d5f2bf52682",
        isFullBleed: true,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F049_CLAP3%2F2_CLAP3_plans_avant_web.jpg?alt=media&token=5b11f779-38ec-4fad-9ee4-b453e7cf7ec7",
        isFullBleed: false,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F049_CLAP3%2F3_CLAP3_plans_apres_web.jpg?alt=media&token=b4ecd71f-d6f0-40cf-8ce9-a0db351c6292",
        isFullBleed: false,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F049_CLAP3%2F4_IMG7214.jpg?alt=media&token=fc269a3e-2bf7-47de-871d-0bf8f3d365f0",
        isFullBleed: true,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F049_CLAP3%2F5_IMG7284.jpg?alt=media&token=1b59ef58-7318-400d-8a0e-fc55149be095",
        isFullBleed: true,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F049_CLAP3%2F6_IMG7316.jpg?alt=media&token=239a5e07-fab8-41ab-b495-dadb42d9a452",
        isFullBleed: true,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F049_CLAP3%2F7_IMG7288.jpg?alt=media&token=9092fa67-5302-4f05-986c-1fd5208d62a1",
        isFullBleed: false,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F049_CLAP3%2F8_IMG7327.jpg?alt=media&token=f2a67e12-4f81-44fc-aca7-1448ca6c1db3",
        isFullBleed: false,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F049_CLAP3%2F9_IMG7341.jpg?alt=media&token=962b7b1f-ffd6-45ad-8a59-690ebff3d721",
        isFullBleed: true,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F049_CLAP3%2F10_IMG7262.jpg?alt=media&token=73528ba3-a466-4971-89b0-2840e3bca66b",
        isFullBleed: false,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F049_CLAP3%2F11_IMG7379.jpg?alt=media&token=69f5f893-680b-4302-82bc-db4a3647d32d",
        isFullBleed: true,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F049_CLAP3%2F12_IMG7391.jpg?alt=media&token=dda3eaf5-537b-4e25-b367-e3b4f09af97e",
        isFullBleed: true,
      },
    ],
  },
  "040_CRC17": {
    title: "040 CRC17",
    path: "040_CRC17",
    additionalInfo: {
      type: "Concours",
      location: "Tour final",
      d1: "Porrentruy (JU)",
      d2: "Centre de recherche et de conservation des collections du Jurassica Muséum",
    },
    picture_HD:
      "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F040_CRC17%2F01_WEB_CRC17_Image.jpg?alt=media&token=0360d9e1-4947-4bd0-b97c-3153e9a2d124",
    main_description: {
      STATUT: "Concours, Tour Final",
      SITE: "Le Jurassica Muséum à Porrentruy (JU)",
      DATE: "Décembre 2018",
      PROGRAMME:
        "Centre de recherche et de conservation des collections archéologiques et paléontologique du canton du Jura",
      CLIENT: "Le gouvernement de la république et Canton du Jura",
    },
    pictures: [
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F040_CRC17%2F01_WEB_CRC17_Image.jpg?alt=media&token=0360d9e1-4947-4bd0-b97c-3153e9a2d124",
        isFullBleed: true,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F040_CRC17%2F02_WEB_CRC17_Plan-Masse.jpg?alt=media&token=e573205a-9fed-4a26-a382-17c2d845d7f3",
        isFullBleed: true,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F040_CRC17%2F03_WEB_CRC17_Plan-Rez.jpg?alt=media&token=ad66be0f-1085-4116-9f60-26c40f61617b",
        isFullBleed: true,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F040_CRC17%2F04_WEB_CRC17_Plans.jpg?alt=media&token=fd3124f1-1cd5-42ad-bc0f-0ca8d0fb7f6d",
        isFullBleed: true,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F040_CRC17%2F05_WEB_CRC17_Elevation.jpg?alt=media&token=c0975e9e-4ba7-497d-ac48-ebc09e40b573",
        isFullBleed: true,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F040_CRC17%2F06_WEB_CRC17_Coupe-A.jpg?alt=media&token=abe750ba-15ac-48b9-8e75-2ce40c28a1ab",
        isFullBleed: true,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F040_CRC17%2F07_WEB_CRC17_Coupe-B.jpg?alt=media&token=9c00d1f6-5866-41b9-b65f-997c069fb7ab",
        isFullBleed: true,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F040_CRC17%2F08_WEB_CRC17_Coupe-B.jpg?alt=media&token=8c14fe44-9726-434b-852a-08260bdb59bf",
        isFullBleed: true,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F040_CRC17%2F09_WEB_CRC17_Coupe-50_2.jpg?alt=media&token=e6c08b02-37e2-4389-a244-2abb3b6b3241",
        isFullBleed: true,
      },
    ],
  },
  "045_SIER": {
    title: "045 SIER",
    path: "045_SIER",
    additionalInfo: {
      type: "Concours",
      date: "Août 2018",
      location: "Sierre (VS)",
      d1: "Centrale d'engagement",
      d2: "Avec Schnetzer Puskas Ing.",
      d3: "et RGR Ingénieurs Conseil",
    },
    picture_url_description: SIER_desc_picture,
    picture_HD:
      "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F045_SIER%2F1_WEB_Sierre_Image_TEST.jpg?alt=media&token=ec23939e-4ebc-4927-8042-14adcee612e4",
    main_description: {
      STATUT: "Concours",
      SITE: "Rue de l'Escala, Sierre (VS)",
      DATE: "Août 2018",
      PROGRAMME: "Réunion sous un même toit des centrales d’engagement 112-117-118-144",
      CLIENT: "Etat du Valais",
      COLLABORATEURS: "Schnetzer Puskas Ingenieure AG Basel, RGR ingénieurs conseil SA Lausanne",
    },
    pictures: [
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F045_SIER%2F1_WEB_Sierre_Image_TEST.jpg?alt=media&token=ec23939e-4ebc-4927-8042-14adcee612e4",
        isFullBleed: true,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F045_SIER%2F2_WEB_SIE_plan%20base_.jpg?alt=media&token=8423eba0-da64-4bf1-9945-2db815c07b68",
        isFullBleed: true,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F045_SIER%2F3_WEB_SIE_Plan%20REZ.jpg?alt=media&token=f0e8437f-92cf-4132-8361-83f8265f67ff",
        isFullBleed: true,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F045_SIER%2F4_WEB_SIE_Plan%20ETAGE-.jpg?alt=media&token=9789f84b-d647-417a-ba4e-8d86ac854f25",
        isFullBleed: true,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F045_SIER%2F5_WEB_SIE_Plan%20ETAGE-2.jpg?alt=media&token=753c9bce-c41b-4b23-87cc-3d1eff599908",
        isFullBleed: true,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F045_SIER%2F6_WEB_SIE_Facade%20longue.jpeg?alt=media&token=1578530a-15e9-439b-8bfb-687f9a0333e5",
        isFullBleed: true,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F045_SIER%2F7_WEB_SIE_Coupe%20longue.jpg?alt=media&token=3defdf8f-89dd-415f-a20d-8e43e2553860",
        isFullBleed: true,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F045_SIER%2F8_WEB_SIE_Coupe%20courte.jpeg?alt=media&token=0f20c37d-16df-42aa-8209-c357d4a0b1bf",
        isFullBleed: true,
      },
    ],
  },
  "044_BAINS": {
    title: "044 BAINS",
    path: "044_BAINS",
    additionalInfo: {
      type: "Construit",
      date: "Octobre 2018",
      location: "Genève (GE)",
      d1: "Rénovation d’un appartement",
      d2: "Avec Ingphi SA",
      d3: "Photos@Matthieu Gafsou",
    },
    picture_url_description: BAINS_desc_picture,
    picture_HD:
      "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F044_BAINS%2F1_Archi_genf_re%CC%81nov_1.jpg?alt=media&token=2fe30baa-18dd-4459-b587-128ec43ab8fe",
    main_description: {
      STATUT: "Construit",
      SITE: "Rue des Bains 29, Genève (GE)",
      DATE: "Septembre 2018",
      PROGRAMME: "Rénovation d’un appartement, ajout d’une salle de bains et réorganisation de l’espace intérieur",
      CLIENT: "Privé",
      PHOTOGRAPHIES: "Matthieu Gafsou",
      COLLABORATEURS: "Ingphi SA Lausanne",
    },
    pictures: [
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F044_BAINS%2F1_Archi_genf_re%CC%81nov_1.jpg?alt=media&token=2fe30baa-18dd-4459-b587-128ec43ab8fe",
        isFullBleed: false,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F044_BAINS%2F10_Archi_genf_re%CC%81nov_16.jpg?alt=media&token=8bb2f3c2-d210-435b-a450-d0dce1de1471",
        isFullBleed: false,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F044_BAINS%2F2_PLAN%20existant-Sheet%20Title.jpg?alt=media&token=51010557-0031-42eb-b2a3-f6a807cb0bcf",
        isFullBleed: false,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F044_BAINS%2F3_PLAN-Sheet%20Title.jpg?alt=media&token=82b04997-9aed-4866-ba65-41175a9fbd5b",
        isFullBleed: false,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F044_BAINS%2F4_BAI_180725_Plan.jpg?alt=media&token=49d9da60-0a08-40ff-aec9-7da2ca02bb6a",
        isFullBleed: false,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F044_BAINS%2F5_Archi_genf_re%CC%81nov_4.jpg?alt=media&token=e0a51550-876a-4483-a6b0-6633cb7e8d35",
        isFullBleed: false,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F044_BAINS%2F6_Archi_genf_re%CC%81nov_7.jpg?alt=media&token=af02c4b7-ef75-4ca4-b8fc-d238d9d173ff",
        isFullBleed: false,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F044_BAINS%2F8_Archi_genf_re%CC%81nov_11.jpg?alt=media&token=628d58ab-d53c-4ccc-8dc6-2b111baa09e4",
        isFullBleed: false,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F044_BAINS%2F9_Archi_genf_re%CC%81nov_13.jpg?alt=media&token=17d62e2e-ee28-44ec-8c35-7fcc9cb2c76b",
        isFullBleed: false,
      },
    ],
  },
  "017_PDL": {
    title: "017 PDL",
    path: "017_PDL",
    additionalInfo: {
      type: "Concours",
      date: "6ème rang, 3ème prix",
      location: "Août 2017",
      d1: "Lausanne (VD)",
      d2: "170 logements et crèche",
      d3: "Plaines-du-Loup, Pièce C",
      d4: "Avec Eranthis Paysagiste",
    },
    picture_url_description: PDL_desc_picture,
    picture_HD:
      "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F017_PDL%2F1_A_North_003_ACL.jpg?alt=media&token=555e2200-87f0-4f19-8bf3-ae3c01fce3f6",
    main_description: {
      STATUT: "Concours, 6EME RANG - 3EME PRIX",
      SITE: "Pièce C, quartier des Plaines-du-Loup, Lausanne (VD)",
      DATE: "Août 2017",
      PROGRAMME:
        "Construction d’environ 170 logements, une crèche et des commerces pour la pièce C des Plaines-du-Loup",
      CLIENT: "Ville de Lausanne",
      COLLABORATEURS: "Eranthis paysagistes concepteurs Lyon",
    },
    pictures: [
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F017_PDL%2F1_A_North_003_ACL.jpg?alt=media&token=555e2200-87f0-4f19-8bf3-ae3c01fce3f6",
        isFullBleed: true,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F017_PDL%2F2_PDL_170828_500_GA.jpg?alt=media&token=f25978c6-08e6-40b3-a028-e2f8ab69fbb2",
        isFullBleed: true,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F017_PDL%2F3_LOC_layout_Plan%20rdc_200_V3.jpg?alt=media&token=a3035636-93d9-4337-b1f7-3f664d76d067",
        isFullBleed: true,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F017_PDL%2F4_LOC_layout_Plan%20%2B1%262_200.jpg?alt=media&token=a08eab48-d15a-409b-89f3-5c69b79e1ccd",
        isFullBleed: true,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F017_PDL%2F5_LOC_layout_Facade%20Sud_200.jpg?alt=media&token=f8812bf7-2159-4b8f-b2a1-e80b36cc61dd",
        isFullBleed: true,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F017_PDL%2F6_LOC_layout_Facade%20Nord_200.jpg?alt=media&token=4aa90e11-1c4a-4bc3-ae15-24a4923408a0",
        isFullBleed: true,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F017_PDL%2F7_LOC_layout_Coupe%20placette%20privee_200.jpg?alt=media&token=f5cbc1b6-b9d9-45b7-91da-7d0991c53149",
        isFullBleed: true,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F017_PDL%2F8_South_004.jpg?alt=media&token=2f91a431-656b-4dba-9dc0-b1d3a6fba5d7",
        isFullBleed: true,
      },
    ],
  },
  "030_SIVI": {
    title: "030 SIVI",
    path: "030_SIVI",
    additionalInfo: {
      type: "Concours",
      date: "Mai 2018",
      location: "Siviriez (FR)",
      d1: "Etablissement médico-social",
    },
    picture_url_description: SIVI_desc_picture,
    picture_HD:
      "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F030_SIVI%2FWEB_SIVI_Pour_la_Grille.jpg?alt=media&token=d6d6c67b-7ecb-4a6d-9215-f3c3c7272848",
    main_description: {
      STATUT: "Concours",
      SITE: "Route de Romont, Siviriez (FR)",
      DATE: "Avril 2018",
      PROGRAMME: "Construction d’un établissement médico-social (EMS)",
      CLIENT: "Réseau santé de la Glâne",
    },
    pictures: [
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F030_SIVI%2FWEB_SIVI_Pour_la_Grille.jpg?alt=media&token=d6d6c67b-7ecb-4a6d-9215-f3c3c7272848",
        isFullBleed: true,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F030_SIVI%2F2_REZ_200-Description.jpg?alt=media&token=9b31390e-e70f-4a4d-a5aa-534a3ef3f646",
        isFullBleed: true,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F030_SIVI%2F3_PREMIER_200-Description.jpeg?alt=media&token=3e968a11-b239-4fd7-928a-918d74c3c10d",
        isFullBleed: true,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F030_SIVI%2F4_ELEVATION%20LATERALE-Description.jpg?alt=media&token=64178d3e-0652-41fc-8977-fb112ba36410",
        isFullBleed: true,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F030_SIVI%2F5_Coupe%20AA-Description.jpg?alt=media&token=db06458b-0e50-48ba-82e0-c93bf15cfd9f",
        isFullBleed: true,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F030_SIVI%2F6_180411_SIV_Coupe%2050.jpg?alt=media&token=6c855c79-8285-441d-9cf5-a93039b1c7dd",
        isFullBleed: false,
      },
    ],
  },
  "023_BLVD": {
    title: "023 BLVD",
    path: "023_BLVD",
    additionalInfo: {
      type: "Concours",
      date: "Février 2018",
      location: "Lausanne (VD)",
      d1: "Ecole du Belvédère",
      d2: "Avec Schnetzer Puskas Ing.",
    },
    picture_url_description: BLVD_desc_picture,
    picture_HD:
      "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F023_BLVD%2F1_WEB_BELV_Vue-Ext.jpg?alt=media&token=cbf39fa2-3e07-44c9-b478-3034b4584a5d",
    main_description: {
      STATUT: "Concours",
      SITE: "Chemin des Croix-Rouges, Lausanne (VD)",
      DATE: "Janvier 2018",
      PROGRAMME:
        "Etablissement primaire et secondaire du Belvédère, construction de nouveaux locaux scolaires et d’une salle de gymnastique",
      CLIENT: "Ville de Lausanne",
      COLLABORATEURS: "Schnetzer Puskas Ingenieure AG Basel",
    },
    pictures: [
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F023_BLVD%2F9_BEL_180120_Coupe%2050.jpg?alt=media&token=e4ecb8af-a29c-466e-8d3b-a14ee0972cd1",
        isFullBleed: true,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F023_BLVD%2F1_WEB_BELV_Vue-Ext.jpg?alt=media&token=cbf39fa2-3e07-44c9-b478-3034b4584a5d",
        isFullBleed: true,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F023_BLVD%2F2_BEL_180125_Plan%20masse.jpg?alt=media&token=fc59c448-f8dd-4d74-8342-e5b68874c5b4",
        isFullBleed: true,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F023_BLVD%2F3_N2_200-Description.jpg?alt=media&token=1bafc268-fc07-4801-9dd3-e74f441930fa",
        isFullBleed: true,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F023_BLVD%2F4_N2_201-Description.jpg?alt=media&token=faa1b7d0-e1c2-4783-87c8-389009936992",
        isFullBleed: true,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F023_BLVD%2F5_LOC_layout_Facade%20Sud_200_1.jpg?alt=media&token=8baa25f8-5af9-40d8-bfe5-a25e1ad01a50",
        isFullBleed: true,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F023_BLVD%2F6_LOC_layout_Coupe%20longue_200.jpg?alt=media&token=d44188b7-6042-4a36-b217-8399876a8667",
        isFullBleed: true,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F023_BLVD%2F7_WEB_BEL_Coupe_Perse_A3.jpg?alt=media&token=0276b61f-729c-40df-89e2-51ecc2aa77d2",
        isFullBleed: true,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F023_BLVD%2F8_LOC_layout_Coupe%20transv_200.jpg?alt=media&token=cdd354a8-de4a-4456-80f8-787251f5ac53",
        isFullBleed: true,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F023_BLVD%2F10_WEB_BELV_Vue-Int.jpg?alt=media&token=cbeb99c1-e835-46e7-8376-a4aa2e96dbdb",
        isFullBleed: false,
      },
    ],
  },
  "015_MHNG": {
    title: "015 MHNG",
    path: "015_MHNG",
    additionalInfo: {
      type: "Concours",
      date: "Septembre 2017",
      location: "Genève (GE)",
      d1: "Archives du Musée d’Histoire Naturelle",
      d2: "Avec Atelier Geco Basel",
      d3: "et Energa sàrl",
    },
    picture_url_description: MHN_desc_picture,
    picture_HD:
      "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F015_MHNG%2F1_outside.jpg?alt=media&token=b2fb28b7-0c8b-4c37-92f1-840b0f00846b",
    main_description: {
      STATUT: "Concours",
      SITE: "Le parc de Malagnou à Genève (GE)",
      DATE: "Septembre 2017",
      PROGRAMME: "Construction d’une extension des archives du Muséum d’histoire naturelle de Genève",
      CLIENT: "Ville de Genève",
      COLLABORATEURS: "Atelier Geco Basel, Energa sàrl Lausanne",
    },
    pictures: [
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F015_MHNG%2F1_outside.jpg?alt=media&token=b2fb28b7-0c8b-4c37-92f1-840b0f00846b",
        isFullBleed: true,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F015_MHNG%2F2_WEB_MHNG_Plan_Masse.jpg?alt=media&token=efc0e4f9-b50d-4e79-a57c-66c4b8dfa0ba",
        isFullBleed: true,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F015_MHNG%2F3_WEB_MHNG_Plan-Rez_1-200.jpg?alt=media&token=f49eefcd-1326-4540-8033-53b0da18d8a2",
        isFullBleed: false,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F015_MHNG%2F4_WEB_MHNG_Plan_1_1-200.jpg?alt=media&token=b713b9a8-6101-4962-8d9a-37b71d2d49d5",
        isFullBleed: false,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F015_MHNG%2F5_WEB_MHNG_Section_1-200.jpg?alt=media&token=8d5a68ef-cd42-49d7-87e6-547cd6d96240",
        isFullBleed: false,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F015_MHNG%2F6_PLAZA.jpg?alt=media&token=0e8ed29c-732f-4ba0-8e6d-dac6044c5f88",
        isFullBleed: true,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F015_MHNG%2F7_inside.jpg?alt=media&token=04baaf8a-1a89-4d61-bbe3-cf57fc1875f3",
        isFullBleed: true,
      },
    ],
  },
  "008_DES": {
    title: "008 DES",
    path: "008_DES",
    additionalInfo: {
      type: "Concours",
      date: "Avril 2016",
      location: "Lausanne (VD)",
      d1: "110 logements et crêche",
      d2: "Quartier du Désert",
      d3: "Avec M. Lepoutre architecte",
    },
    picture_url_description: DES_desc_picture,
    picture_HD:
      "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F008_DES%2F1_WEB_DES_Vue-Nord.png?alt=media&token=3af717e6-ec82-4c40-8d7b-70c64fa41e6d",
    main_description: {
      STATUT: "Concours",
      SITE: "Lausanne, quartier du Désert (VD)",
      DATE: "Mars 2016",
      PROGRAMME: "Construction de 110 logements et une crêche dans le quartier du Désert",
      CLIENT: "Etablissement Cantonal d’Assurance",
      COLLABORATEURS: "Martin Lepoutre",
    },
    pictures: [
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F008_DES%2F1_WEB_DES_Vue-Nord.png?alt=media&token=3af717e6-ec82-4c40-8d7b-70c64fa41e6d",
        isFullBleed: true,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F008_DES%2F2_WEB_DES_plan-Rez_400.jpg?alt=media&token=d4d2ff9b-f1cc-4e6b-866c-5f5ea9b6d754",
        isFullBleed: false,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F008_DES%2F3_WEB_DES_plan-400.jpg?alt=media&token=b0920c18-a973-4162-98ec-e9bbdd3587eb",
        isFullBleed: false,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F008_DES%2F4_WEB_DES_plan-Typo_100.jpg?alt=media&token=ca60aa53-13e2-4b8a-b5b2-eef69bd27f96",
        isFullBleed: true,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F008_DES%2F5_WEB_DES_coupes-400.jpg?alt=media&token=e108507b-8500-427d-943a-c9e66ee1c71e",
        isFullBleed: true,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F008_DES%2F6_WEB_DES_Coupes-Long_400.jpg?alt=media&token=48319801-efa4-4ee1-bbb9-802ea0237a7e",
        isFullBleed: true,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F008_DES%2F7_WEB_DES_Vue-Sud.jpg?alt=media&token=15caa6b3-9ee8-4f66-ae13-f77802dde44c",
        isFullBleed: true,
      },
    ],
  },
  "004_BRUY": {
    title: "004 BRUY",
    path: "004_BRUY",
    additionalInfo: {
      type: "Construit",
      date: "Août 2016",
      location: "La Bruyere (France)",
      d1: "Rénovation d’une ferme en pisé",
    },
    picture_url_description: BRU_desc_picture,
    picture_HD:
      "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F004_BRU%2F1_BRU_001_Bat-NEW.jpg?alt=media&token=9d1fa682-bf38-41f8-a4be-8a405a9e2998",
    main_description: {
      STATUT: "Construit",
      SITE: "La Bruyere de Romagnieu, Isère (France)",
      DATE: "Août 2016",
      PROGRAMME: "Rénovation de deux maisons en pisé (terre-paille)",
      CLIENT: "Privé",
    },
    pictures: [
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F004_BRU%2F1_BRU_001_Bat-NEW.jpg?alt=media&token=9d1fa682-bf38-41f8-a4be-8a405a9e2998",
        isFullBleed: true,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F004_BRU%2FWEB_BRUY_Fene%CC%82tre.jpg?alt=media&token=bce2dd9a-685c-4bed-a3d1-bb0376a324b2",
        isFullBleed: false,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F004_BRU%2F8_WEB_BRUY_Vue-Chantier_1.jpg?alt=media&token=e8772e65-de9a-42c4-a097-2ed0ec77a29f",
        isFullBleed: true,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F004_BRU%2F2_WEB_BRUY_plan-masse_2.jpg?alt=media&token=883c67b9-0558-4c2e-93c6-940f8c53585b",
        isFullBleed: true,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F004_BRU%2F3_WEB_BRUY_planrez_1-60.jpg?alt=media&token=fd87f909-b206-43dc-923d-ff0d82c43caf",
        isFullBleed: true,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F004_BRU%2F4_WEB_BRUY_planetage_1-60_1.jpg?alt=media&token=8d37acac-b8fa-42b7-bae6-28405e03e722",
        isFullBleed: true,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F004_BRU%2F5_WEB_BRUY2_Plan-rez_1-60_1.jpg?alt=media&token=884b22f1-0334-413b-b2d3-c85e6ada5df7",
        isFullBleed: true,
      },
      {
        lien:
          "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/Projects%2F004_BRU%2F6_WEB_BRUY2_Plan-etage_1-60.jpg?alt=media&token=823b7ce2-fe2c-46ff-8199-67783f864f0d",
        isFullBleed: true,
      },
    ],
  },
};

import React, { Component } from "react";
import styled from "styled-components";

import TA_logo_ARCHI from "../../assets/logos/TA_logo_ARCHI_2.svg";
import TA_logo_TOTEM from "../../assets/logos/TA_logo_TOTEM_2.svg";
import { VAUD } from "./constants";

class Landing extends Component {
  render() {
    const SEO = `TOTEM ARCHITECTURE Sàrl - Rue Cingria 3, 1205 Genève - Av. Louis Ruchonnet 2bis, 1003 Lausanne - ${VAUD}`;

    return (
      <Container>
        <ContainerRow>
          <LogoWrapper src={TA_logo_TOTEM} />
        </ContainerRow>

        <Entrance href="projects">{SEO}</Entrance>

        <ContainerRow isBottom>
          <LogoWrapper src={TA_logo_ARCHI} isBottom />
          <BulletLinkWrapper>
            <Bullet>●</Bullet>
            <Mail href="mailto:info@totemarchi.ch">info@totemarchi.ch</Mail>
          </BulletLinkWrapper>
        </ContainerRow>
      </Container>
    );
  }
}

export default Landing;

const Container = styled.div``;

const Entrance = styled.a`
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  text-indent: -999999px;
`;

const ContainerRow = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.isBottom ? "row-reverse" : "row")};
  align-items: ${(props) => (props.isBottom ? "baseline" : "end")};
  justify-content: space-between;
  height: 14%;
  position: absolute;
  bottom: ${(props) => props.isBottom && "calc(0% + 15px)"};
  top: ${(props) => !props.isBottom && "calc(0% + 15px)"};
  width: 100%;
  overflow: hidden;

  @media (max-width: 1100px) {
    height: 11%;
  }

  @media (max-width: 700px) {
    height: 7%;
  }

  @media (max-width: 600px) {
    height: 6%;
  }

  @media (max-width: 500px) {
    height: 5%;
  }

  @media (max-width: 415px) {
    height: 4%;
  }
`;

const LogoWrapper = styled.img`
  height: 100%;
  left: ${(props) => !props.isBottom && "calc(0% + 16px)"};
  right: ${(props) => props.isBottom && "calc(0% + 16px)"};
  position: absolute;
`;

const BulletLinkWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 26px;
  bottom: 0;

  @media (max-width: 700px) {
    left: 16px;
  }
`;

const Mail = styled.a`
  z-index: 100;
  font-family: "SuisseIntl Medium";
  font-size: 20px;
  color: inherit;
  text-decoration: none;

  @media (max-width: 700px) {
    font-size: 12px;
  }
`;

const Bullet = styled.span`
  font-family: "SuisseIntl Bold";
  font-size: 26px;

  @media (max-width: 700px) {
    font-size: 18px;
  }
`;

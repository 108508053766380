import React from "react";
import { Link, withRouter } from "react-router-dom";
import styled from "styled-components";
import Carousel from "nuka-carousel";

import { PROJECTS_LIST } from "../../containers/Projects/constants";
import { SCREENS_LIST } from "../Header/constants";
import TA_logo_TOTEM from "../../assets/logos/TA_logo_TOTEM_2.svg";
import TA_logo_ARCHI from "../../assets/logos/TA_logo_ARCHI_2.svg";
import LEFT from "../../assets/logos/T_Left.svg";
import RIGHT from "../../assets/logos/T_Right.svg";

class Project extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      project: PROJECTS_LIST[props.match.params.id],
      isOpen: false,
      showControl: false,
      isMobile: window.innerWidth < 500
    };
  }

  componentDidMount() {
    window.addEventListener('orientationchange', this.updateHeader)
  }

  componentWillUnmount() {
    window.removeEventListener('orientationchange', this.updateHeader)
  }

  updateHeader = () => {
    window.setTimeout(() => {
      const isMob = window.innerWidth < 500;
      this.setState({ isMobile: isMob });
    }, 200);
  }

  toggleOpenInfo = () => this.setState({ isOpen: !this.state.isOpen });
  showControl = () => this.setState({ showControl: true });

  renderCenterLeftControls = ({ previousSlide }) => {
    if (this.state.showControl) {
      return (
        <ButtonNavSlider onClick={previousSlide}>
          <LogoNav src={LEFT} />
        </ButtonNavSlider>
      );
    }

    return null;
  };

  renderCenterRightControls = ({ nextSlide }) => {
    if (this.state.showControl) {
      return (
        <ButtonNavSlider onClick={nextSlide}>
          <LogoNav src={RIGHT} />
        </ButtonNavSlider>
      );
    }

    return null;
  };

  render() {
    return (
      <Container>
        <ContainerHeader isOpen={this.state.isOpen}>
          <ContainerLogo to={{ pathname: SCREENS_LIST.projects, state: this.state.project.path }}>
            <Logo src={TA_logo_TOTEM} />
          </ContainerLogo>

          <ModalContainer isOpen={this.state.isOpen}>
            <Bullet onMouseOver={this.toggleOpenInfo} onMouseLeave={this.toggleOpenInfo}>●</Bullet>
            {this.state.isOpen && (
              <AdditionalInfo>
                <Title>{this.state.project.title}</Title>
                {Object.keys(this.state.project.main_description).map(key => {
                  return (
                    <Text key={key}>
                      {key} : {this.state.project.main_description[key]}
                    </Text>
                  );
                })}
              </AdditionalInfo>
            )}
          </ModalContainer>

          <ContainerLogo to={{ pathname: SCREENS_LIST.projects, state: this.state.project.path }}>
            <Logo src={TA_logo_ARCHI} isleft />
          </ContainerLogo>
        </ContainerHeader>

        <ContainerCarousel>
          {this.state.isMobile
            ? 
            <Carousel
            wrapAround={true}
            renderCenterLeftControls={this.renderCenterLeftControls}
            renderCenterRightControls={this.renderCenterRightControls}
            renderBottomCenterControls={null}
          >
            {this.state.project.pictures.map(pic => {
              return (
                <Slide
                  key={pic.lien}
                  src={pic.lien}
                  isFullBleed={false}
                  isMobile={this.state.isMobile}
                  onLoad={() => {
                    window.dispatchEvent(new Event("resize"));
                    this.showControl();
                  }}
                />
              );
            })}
          </Carousel>
            :
            <Carousel
            wrapAround={true}
            renderCenterLeftControls={this.renderCenterLeftControls}
            renderCenterRightControls={this.renderCenterRightControls}
          >
            {this.state.project.pictures.map(pic => {
              return (
                <Slide
                  key={pic.lien}
                  src={pic.lien}
                  isFullBleed={pic.isFullBleed}
                  isMobile={this.state.isMobile}
                  onLoad={() => {
                    window.dispatchEvent(new Event("resize"));
                    this.showControl();
                  }}
                />
              );
            })}
          </Carousel>
          }
        </ContainerCarousel>
      </Container>
    );
  }
}

export default withRouter(Project);

const Container = styled.div`
  width: 100%;
  overflow-y: hidden;
  position: fixed;
`;

const Title = styled.span`
  font-family: "SuisseIntl Medium";
  font-size: calc(6px + 3vw);
`;

const Text = styled.span`
  font-family: "SuisseIntl Medium";
  font-size: calc(6px + 1vw);
`;

const Bullet = styled.span`
  font-family: "SuisseIntl Bold";
  font-size: calc(12px + 3.5vw);
  margin-left: 2vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  width: fit-content;
`;

const ModalContainer = styled.div`
  background-color: ${props => (props.isOpen ? "white" : "transparent")};
  width: 60%;
  padding-bottom: 3px;
`;

const AdditionalInfo = styled.div`
  padding: 0 2vw 2vw 2vw;
  width: calc(60% - 4vw);
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: white;
`;

const ContainerHeader = styled.div`
  width: 100vw;

  height: 10vh;
  min-height: 50px;

  @media (max-width: 700px) {
    height: 5vh;
  }

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  position: absolute;
  z-index: 1000;
`;

const ContainerCarousel = styled.div`
  ul.slider-list {
    cursor: unset !important;
  }

  @media (max-width: 500px) {
    ul li.slider-slide {
      display: flex !important;
      justify-content: center;
      max-height: ${window.innerHeight}px !important;
    }
  }
`;

const Slide = styled.img`
  max-width: 100%;
  ${props => (props.isMobile ? `max-height: 100vh;` : `height: 100vh;`)}
  object-fit: ${props => (props.isFullBleed ? "cover" : "contain")};
`;

const Logo = styled.img`
  height: calc(3px + 2.8vw);

  @media (max-width: 500px) {
    height: 20px;
  }
`;

const ContainerLogo = styled(Link)`
  color: inherit;
  text-decoration: none;
  margin: 20px;

  @media (max-width: 450px) {
    margin: 20px 10px;
  }

  display: flex;
  justify-content: center;
  align-items: center;

  ${props => (props.isleft ? "right: 0%;" : "left: 0%;")}
`;

const ButtonNavSlider = styled.div`
  cursor: pointer;
`;

const LogoNav = styled.img`
  height: 3vw;
  padding: 25px;
`;

export const INFO = [
  {
    name: "GIULIA ALTARELLI",
    diploma: "ARCHITECTE EPFL SIA",
    experience: [
      {
        date: "2017 - auj.",
        description: "Création de TOTEM ARCHITECTURE",
      },
      {
        date: "2013 - auj.",
        description: "Localarchitecture, Lausanne",
      },
      {
        date: "2016 - 2018",
        description: "Laboratoire EAST-EPFL, Lausanne - Assistante Profs. Anja et Martin Fröhlich",
      },
      {
        date: "2013",
        description: "Diplôme architecte EPFL",
      },
      {
        date: "2011 - 2011",
        description: "EMBT Arquitectes, Barcelone",
      },
      {
        date: "2010 - 2011",
        description: "Localarchitecture, Lausanne",
      },
    ],
  },
  {
    name: "ELSA BENIADA",
    diploma: "ARCHITECTE EPFL SIA",
    experience: [
      {
        date: "2017 - auj.",
        description: "Création de TOTEM ARCHITECTURE",
      },
      {
        date: "2018 - auj.",
        description: "MASTER EPFL en expertise immobilière",
      },
      {
        date: "2016 - 2017",
        description: "Steiner SA, Tolochenaz",
      },
      {
        date: "2014 - 2015",
        description: "Centre Romand en formation continue, Lausanne - Diplôme de comptabilité-gestion",
      },
      {
        date: "2013 - 2016",
        description: "Bakker & Blanc Architectes associés, Lausanne",
      },
      {
        date: "2013",
        description: "Diplôme architecte EPFL",
      },
      {
        date: "2011 - 2011",
        description: "EM2N Architekten, Zürich",
      },
      {
        date: "2010 - 2011",
        description: "Brandhluber + Architekten, Berlin",
      },
    ],
  },
  {
    name: "AUGUSTIN CLEMENT",
    diploma: "ARCHITECTE EPFL SIA",
    experience: [
      {
        date: "2017 - auj.",
        description: "Création de TOTEM ARCHITECTURE",
      },
      {
        date: "2017 - auj.",
        description: "LABA - EPFL, Lausanne - Assistant Prof. Harry Gugger",
      },
      {
        date: "2013 - 2017",
        description: "Herzog & De Meuron, Basel",
      },
      {
        date: "2013 - 2013",
        description: "Localarchitecture, Lausanne",
        more: "Concours",
      },
      {
        date: "2013",
        description: "Diplôme architecte EPFL",
      },
      {
        date: "2011 - 2011",
        description: "Foster +Partners, Londres",
      },
      {
        date: "2010 - 2011",
        description: "2B Stratégies Urbaines Concrètes, Lausanne",
      },
      {
        date: "2009 -2009",
        description: "ALICE - EPFL, Lausanne - Construction pavillon Evolver à Zermatt",
      },
    ],
  },
];

export const INTRO = {
  a:
    "Le totem est un élément planté, enraciné dans le contexte dans lequel il se trouve. Souvent construit en bois, il a une identité forte, tout en étant en dialogue avec le paysage et le contexte bâti qui l’entoure. C’est un élément fédérateur qui unit les membres d’une famille ou d’un groupe, l’ancêtre du collectif.",
};

import React from "react";
import styled from "styled-components";
import Layout from "../Layout";
import { NEWS } from "./constants";

const News = () => (
  <Layout>
    <MainContainer>
      {NEWS.map((nw, index) => {
        return (
          <FlexRow key={index}>
            <FlexColumn>
              <Image
                src={nw.pic}
                onLoad={() => {
                  window.dispatchEvent(new Event("resize"));
                }}
              />
            </FlexColumn>

            <FlexColumn>
              <TitleContainer>
                <Bullet>●</Bullet>
                {nw.title}
              </TitleContainer>
              <Title isLast>{nw.ref}</Title>

              <Text>{nw.date}</Text>
              <Text>{nw.com}</Text>

              <div>
                {!!nw.infoLink && <Text>{nw.infoLink}</Text>}
                {!!nw.link && <Link href={nw.link}>{nw.link}</Link>}
              </div>

              <div>
                {!!nw.infoLink2 && <Text>{nw.infoLink2}</Text>}
                {!!nw.link2 && <Link href={nw.link}>{nw.link2}</Link>}
              </div>
            </FlexColumn>
          </FlexRow>
        );
      })}
    </MainContainer>
  </Layout>
);

const MainContainer = styled.div`
  margin-top: 4px;
  margin-right: 20px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: 500px) {
    margin-right: 0px;
  }

  @media (max-width: 450px) {
    align-items: center;
  }
`;

const FlexRow = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column-reverse;
  width: 50%;
  margin-bottom: 20px;

  @media (max-width: 700px) {
    width: 100%;
  }
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 700px) {
    margin-bottom: 20px;
  }
`;

const Image = styled.img`
  margin-top: 10px;
  object-fit: contain;
  width: 100%;

  @media (max-width: 700px) {
    width: 100%;
  }
`;

const Title = styled.span`
  font-family: "SuisseIntl Medium";
  font-size: 20px;
  ${(props) => props.isLast && "margin-bottom: 20px;"};

  @media (max-width: 363px) {
    font-size: 18px;
  }
`;

const TitleContainer = styled(Title)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const Text = styled.span`
  font-family: "SuisseIntl Medium";
  font-size: 18px;

  @media (max-width: 363px) {
    font-size: 16px;
  }
`;

const Link = styled.a`
  text=decoration: none;
  color: inherit;
  font-family: "SuisseIntl Medium";
  font-size: 18px;

  @media (max-width: 363px) {
    font-size: 16px;
  }
`;

const Bullet = styled.span`
  font-family: "SuisseIntl Bold";
  font-size: 26px;

  @media (max-width: 700px) {
    font-size: 18px;
  }

  @media (max-width: 500px) {
    font-size: 16px;
  }

  @media (max-width: 450px) {
    font-size: calc(12px + 3.5vw);
  }
`;

export default News;

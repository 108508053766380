import React, { Component } from "react";
import styled from "styled-components";
import { CONTACT_INFO } from "./constants";

export default class Contact extends Component {
  render() {
    return (
      <Container>
        <div>
          <ColumContainer>
            <Text>{CONTACT_INFO.title}</Text>
            <Text>
              <Bullet>●</Bullet>
              {CONTACT_INFO.adresse1}
            </Text>
            <Text>
              <Bullet>●</Bullet>
              {CONTACT_INFO.phone1}
            </Text>
            <Text>
              <Bullet>●</Bullet>
              <Mail href="mailto:info@totemarchi.ch">info@totemarchi.ch</Mail>
            </Text>
          </ColumContainer>
        </div>
      </Container>
    );
  }
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  justify-content: center;
  align-items: flex-start;

  @media (max-width: 500px) {
    align-items: center;
  }
`;

const ColumContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${(props) => props.marginRight && "margin-right: 15vw;"};

  @media (max-width: 415px) {
    ${(props) => props.marginRight && "margin-right: 20px;"};
  }

  @media (max-width: 352px) {
    ${(props) => props.marginRight && "margin-right: 0px;"};
  }
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 35px;
`;

const Text = styled.span`
  font-family: "SuisseIntl Medium";
  font-size: 18px;
  line-height: 20px;

  @media (max-width: 363px) {
    font-size: 16px;
  }
`;

const Bullet = styled.span`
  font-family: "SuisseIntl Bold";
  font-size: 26px;

  @media (max-width: 700px) {
    font-size: 18px;
  }

  @media (max-width: 450px) {
    font-size: calc(12px + 3.5vw);
  }
`;

const Mail = styled.a`
  color: inherit;
  text-decoration: none;

  font-family: "SuisseIntl Medium";
  font-size: 18px;

  @media (max-width: 363px) {
    font-size: 16px;
  }
`;

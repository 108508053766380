import React from "react";
import { ProjectList } from "../../components";
import Layout from "../Layout";

const Projects = () => (
  <Layout isProjectList>
    <ProjectList />
  </Layout>
);

export default Projects;

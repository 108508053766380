import React, { Component } from "react";
import styled from "styled-components";
import { Header } from "../../components";

export default class Layout extends Component {
  state = { loading: false };

  render() {
    return (
      <MainContainer isContact={this.props.isContact}>
        <Header />
        <ContainerChildren isProjectList={this.props.isProjectList}>
          {this.props.children}
        </ContainerChildren>
      </MainContainer>
    );
  }
}

const MainContainer = styled.div``;

const ContainerChildren = styled.div`
  @media (max-width: 500px) {
    padding: 0 23px;
  }

  ${props =>
    !props.isProjectList &&
    `
      margin-left: calc(80px + 14vw);

      @media (max-width: 700px) {
        margin-left: calc(65px + 14vw);
      }

      @media (max-width: 500px) {
        margin-left: 0;
      }
      `};
`;

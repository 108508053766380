export const NEWS = [
  {
    title: "DÉMARRAGE DE CHANTIER",
    ref: "074 CHAMPEL",
    date: "FEVRIER 2021",
    com: "Rénovation et Transformation d’un appartement à Genève pour une Famille.",
    pic:
      "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/News%2F074_CHAMPEL.jpg?alt=media&token=5eee7afd-5689-4db6-a2ec-bb7680895e9b",
  },
  {
    title: "TOTEM DÉMÉNAGE !",
    ref: "3 rue Cingria, 1205 GE",
    date: "NOVEMBRE 2020",
    com: "Déménagement dans une nouvelle arcade à Genève :",
    pic:
      "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/News%2FIMG_7646.JPG?alt=media&token=f588249f-bf71-4882-ab27-08091b6e609c",
  },
  {
    title: "DEPOSE DE PERMIS POUR UNE SURÉLÉVATION",
    ref: "062_RENENS",
    date: "NOVEMBRE 2020",
    com: "Transformation et surélévation d’un immeuble de logements mixtes",
    pic:
      "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/News%2FREN_PC_Plans_Page_01%20copy.jpg?alt=media&token=b8267daf-c3a2-4e5d-be34-265f61c9597b",
  },
  {
    title: "DÉMARRAGE DE CHANTIER",
    ref: "056_ALPES",
    date: "OCTOBRE 2020",
    com:
      "Rénovation et Transformation d’un appartement à Lausanne pour un photographe. En collaboration avec Alia Bengana",
    pic:
      "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/News%2F056_ALPES.JPG?alt=media&token=53a75f15-58bc-44dd-8175-da9ccbf9b0d9",
  },
  {
    title: "ENSEIGNEMENT A L’EPFL",
    ref: "ALICE YEAR 1",
    date: "SEPTEMBRE 2020",
    com:
      "Depuis septembre, Augustin enseigne en 1ère année à l’EPFL comme Directeur de Studio au sein d’ALICE (Atelier de la Conception de l’Espace). Cette année nous travaillons sur le grand Genève, autour du Rhône.",
    link: "https://www.epfl.ch/labs/alice/",
    infoLink: "Pour plus d’info : ",
    link2: "https://aliceblogs.ch/",
    infoLink2: "Le travail des étudiants : ",
    pic:
      "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/News%2Fbox-augustin.jpg?alt=media&token=4b530bc0-01b6-4a29-8dbc-8deb0186e7f1",
  },
  {
    title: "NOUVEAU PROJET",
    ref: "068_LANCY51",
    date: "JUIN 2020",
    com: "Transformation et extension d’une maison dans le cœur de village de Lancy",
    pic:
      "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/News%2F068_LANCY51.jpg?alt=media&token=38197d26-c075-468a-8305-24ea7c0f0865",
  },
  {
    title: "DEPOSE DE PERMIS",
    ref: "065_BELLOT",
    date: "JUIN 2020",
    com: "Transformation et rénovation de combles d’une maison de Maître classée",
    pic:
      "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/News%2F065_IMG.jpg?alt=media&token=3474e2f4-646f-447d-9bca-a05a7a5bbdce",
  },
  {
    title: "CONCOURS - 3 EME PRIX !",
    ref: "063_BELLEVUE",
    date: "MARS 2020",
    com: "Bâtiment communal et Auberge-Restaurant à Port Gitana",
    pic:
      "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/News%2F063_BELLEVUE.jpg?alt=media&token=c418036c-7264-41de-a73f-6c85ca28c599",
  },
  {
    title: "FIN DE CHANTIER",
    ref: "028 O+S",
    date: "OCTOBRE 2019",
    com: "Restaurant Olive and Squash. Londres",
    pic:
      "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/News%2FIMG_0728.jpg?alt=media&token=a3b63e0a-6d49-446e-bdf5-0fea5c820192",
  },
  {
    title: "ENSEIGNEMENT A L’EPFL",
    ref: "LABA",
    date: "SEPTEMBRE 2019",
    com: "Environmental Objects, Dwelling on Manor 2019/2020",
    link: "https://laba.epfl.ch/",
    pic:
      "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/News%2FPages%20from%201920_LABA_MNR_Syllabus.jpg?alt=media&token=6c2ba420-aad0-406a-8638-38552583d728",
  },
  {
    title: "DÉMARRAGE DE PROJET",
    ref: "028 O&S",
    date: "Février 2019",
    com:
      "Réalisation d’un restaurant à Londres pour Olive & Squash en collaboration avec Architecture Social Club",
    pic:
      "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/News%2FOS_Render_Canary-Wharf.jpg?alt=media&token=1a28ef69-2bca-4da4-8f89-d2209d94635b",
  },
  {
    title: "FIN DE CHANTIER",
    ref: "044 BAINS",
    date: "Septembre 2018",
    com: "Transformation d’un appartement à la Rue des Bains, Genève",
    pic:
      "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/News%2F1_Archi_genf_re%CC%81nov_1.jpg?alt=media&token=94792806-c7bf-411b-9723-37b36bc01a76",
  },
  {
    title: "FIN DE CHANTIER",
    ref: "029 LRMCK",
    date: "Novembre 2018",
    com: "Transformation d’un appartement à Paris",
    pic:
      "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/News%2FWEB_LMCK_Axo_2.jpg?alt=media&token=cc9d0e66-fe24-4605-895c-f628189890c8",
  },
  {
    title: "DÉBUT DE CHANTIER",
    ref: "049 CLAP3",
    date: "Novembre 2018",
    com: "Rénovation d’un appartement à Genève",
    pic:
      "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/News%2F049_Chantier.jpg?alt=media&token=571df2d9-a017-489a-b294-819baa1a2656",
  },
  {
    title: "ENSEIGNEMENT A L’EPFL",
    ref: "LABA",
    date: "Septembre 2018",
    com: "Environmental Objects, Dwelling on FEZ 2018/2019",
    link: "https://laba.epfl.ch/",
    pic:
      "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/News%2F1819_FEZ_Description_light_Page_01.jpg?alt=media&token=73dd29ba-8eb3-4f97-b3d1-265edcb1526b",
  },
  {
    title: "CONCOURS - 3 EME PRIX 6 EME RANG",
    ref: "017 PDL",
    date: "Septembre 2017",
    com: "Projet pour trois bâtiments de logements à Lausanne aux Plaines du Loup",
    pic:
      "https://firebasestorage.googleapis.com/v0/b/totem-archi.appspot.com/o/News%2FA_North_003_ACL.jpg?alt=media&token=59663a8d-863a-443b-a0a2-0897c06201c6",
  },
];
